
import { defineComponent } from "vue";
import { loadAsync } from "jszip";
import { FeatureCollection } from "geojson";

import { addGeoJSON } from "@/shared/DataService";

export default defineComponent({
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    status(): string {
      return this.isLoading
        ? "Loading ..."
        : "Click here to select a data set from the local file system";
    },
  },
  methods: {
    async loadData(zip: File) {
      this.isLoading = true;
      const buffer = await zip.arrayBuffer();
      const unzipped = await loadAsync(buffer);
      try {
        for (const filePath in unzipped.files) {
          const file = unzipped.file(filePath);

          if (!file) throw `Error unzipping file: ${filePath}`;

          const content = await file.async("string");
          const geoJSON: FeatureCollection = JSON.parse(content);
          await addGeoJSON(geoJSON);
        }
      } catch (error) {
        // TODO show error to user
        console.error(error);
      } finally {
        this.isLoading = false;
      }

      this.$router.push("/");
    },
  },
});
