
import dayjs, { UnitType } from "dayjs";
import { ElRadioButton, ElRadioGroup } from "element-plus";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ElRadioGroup,
    ElRadioButton,
  },
  emits: ["dateRangeChanged"],
  data(): { selectedDate: Date; duration: UnitType } {
    return {
      selectedDate: new Date(2021, 6, 15),
      duration: "year",
    };
  },
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    dateRange() {
      this.$emit("dateRangeChanged", this.dateRange);
    },
  },
  computed: {
    formattedDate(): string {
      if (this.duration === "year") {
        return dayjs(this.selectedDate).format("YYYY").toString();
      } else if (this.duration === "month") {
        return dayjs(this.selectedDate).format("YYYY / MM").toString();
      } else if (this.duration === "day") {
        return dayjs(this.selectedDate).format("YYYY / MM / DD").toString();
      }
      throw `Unsupported unitType ${this.duration}`;
    },
    dateRange(): [Date, Date] {
      return [
        dayjs(this.selectedDate).startOf(this.duration).toDate(),
        dayjs(this.selectedDate).endOf(this.duration).toDate(),
      ];
    },
  },
  methods: {
    previous() {
      this.selectedDate = dayjs(this.selectedDate)
        .subtract(1, this.duration)
        .toDate();
    },
    next() {
      this.selectedDate = dayjs(this.selectedDate)
        .add(1, this.duration)
        .toDate();
    },
  },
});
