
import { defineComponent } from "vue";
import { TooltipContent } from ".";

export default defineComponent({
  props: {
    content: {
      type: TooltipContent,
    },
  },
});
