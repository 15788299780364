
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";

import LeafletMap from "@/views/about/Leaflet.vue"; // TODO fix code duplication

export default defineComponent({
  computed: {
    ...mapState(["aboutText"]),
  },
  methods: {
    ...mapActions(["getAboutText"]),
  },
  components: {
    LeafletMap,
  },
  async mounted() {
    await this.getAboutText();
  },
});
