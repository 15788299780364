
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";

import LeafletMap from "./Leaflet.vue";
import { Feature } from "geojson";

import { getFlightsByIds } from "@/shared/DataService";

export default defineComponent({
  computed: {
    ...mapState(["storiesText"]),
  },
  methods: {
    ...mapActions(["getStoriesText"]),
  },
  components: {
    LeafletMap,
  },
  data(): { flights: Feature[] } {
    return {
      flights: [],
    };
  },
  async mounted() {
    await this.getStoriesText();
    const buttons = document.getElementsByClassName("data-link");
    for (const button of buttons) {
      const flightIds = button.getAttribute("data")?.split(",");

      if (!flightIds) throw "data-link requires data attribute";

      // TODO memory leak? Remove in umount()?
      button.addEventListener("click", async () => {
        this.flights = await getFlightsByIds(flightIds);
      });
    }
  },
});
