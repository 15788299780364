
import { defineComponent } from "vue";
import { ElTooltip } from "element-plus";

export default defineComponent({
  components: {
    ElTooltip,
  },
  props: {
    color: { type: String },
    label: { type: String },
    attribution: { type: String },
    description: { type: String },
  },
});
