
import { defineComponent } from "vue";
import dayjs from "dayjs";

export default defineComponent({
  methods: {
    formatDate(date: Date) {
      return dayjs(date).format(this.dayjsFormat);
    },
  },
  props: {
    from: {
      type: Date,
    },
    to: {
      type: Date,
    },
    aircraftName: {
      type: String,
    },
    dayjsFormat: {
      type: String,
      default: "YY/MM/DD HH:mm",
    },
  },
});
